import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './InputFormPage.css'; // Import the styles

const InputFormPage = () => {
  const [amount, setAmount] = useState('');
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();
  const { name } = useParams();

  // Format the amount in Rupiah
  const formatRupiah = (amount) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(amount);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Ensure the amount is within the specified range
    if (amount < 10000 || amount > 10000000) {
      alert('Amount must be between Rp10,000 and Rp10,000,000');
      return;
    }
    // Redirect to the QR code page with amount and userId in the URL
    navigate(`/${name}/${amount}/${userId}`);
  };

  return (
    <div className="form-container">
      <div className="form-card">
        <h1>Payment Details</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="userId">User ID:</label>
            <input
              type="text"
              id="userId"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="amount">Amount</label>
            <input
              type="number"
              id="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              min="10000"
              max="10000000"
              required
            />
            {amount && (
              <p className="formatted-amount">
                {formatRupiah(amount)}
              </p>
            )}
          </div>
          <button type="submit">Generate QRIS</button>
        </form>
      </div>
    </div>
  );
};

export default InputFormPage;
