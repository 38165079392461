import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import QRCodePage from './QRCodePage';
import SuccessPage from './SuccessPage';
import InputFormPage from './InputFormPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:name" element={<InputFormPage />} />
        <Route path="/:name/:amount/:userId" element={<QRCodePage />} />
        <Route path="/success" element={<SuccessPage />} /> {/* Success page route */}
      </Routes>
    </Router>
  );
}

export default App;
